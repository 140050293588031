import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import LanguageSelector from "../LanguageSelector";
import { useTranslation } from "react-i18next";
import countryData from "../../constants/countryData";
import { toast } from "react-toastify";
import Select from "react-select";
import { createSuperAdminUser } from "../../services/authservice";

const SignUp = () => {
  const { t } = useTranslation("login");
  const history = useHistory();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    country: "",
    langKey: "de",
  });

  const countryOptions = countryData.map((country) => ({
    value: country.name,
    label: country.name,
  }));

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCreateAccount = async () => {
    const errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        errors[key] = "This field is required";
      }
    });
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const response = await createSuperAdminUser(formData);
      console.log(response);
      if (response.status === 200) {
        if (!response.data) {
          toast.success("Anmelde-Limit für Konto erreicht.", {
            autoClose: 500,
          });
          history.push("/login");
        } else {
          toast.success("Konto erfolgreich erstellt.", {
            autoClose: 500,
          });
          history.push("/login");
        }
      } else {
        toast.error("Etwas ist schiefgelaufen.", {
          autoClose: 500,
        });
      }
    }
  };

  return (
    <div className="auth">
      <div className="auth_left">
        <div className="card">
          <div className="text-center mb-2">
            <Link className="header-brand" to="/">
              <img
                src="https://boardi.co/assets/images/logo_icon_1.png"
                className="img-fluid mx-auto mb-2"
                alt="/"
                style={{ width: "35px", height: "35px" }}
              ></img>
            </Link>
            <div className="text-center mb-2">
              <LanguageSelector />
            </div>
          </div>
          <div className="card-body">
            <div className="card-title" style={{ fontWeight: 500 }}>
              {t("signUptitle")}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">
                    {t("firstName")}
                    <span
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginLeft: "1px",
                        marginRight: "4px",
                      }}
                    >
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter name"
                  />
                  <p className="text-red-500 text-xs mt-1">
                    {formErrors.firstName}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">
                    {t("lastName")}
                    <span
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginLeft: "1px",
                        marginRight: "4px",
                      }}
                    >
                      *
                    </span>
                  </label>

                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter name"
                  />
                  <p className="text-red-500 text-xs mt-1">
                    {formErrors.lastName}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="form-group">
              <label className="form-label">
                Agent Number
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "1px",
                    marginRight: "4px",
                  }}
                >
                  *
                </span>
              </label>

              <input
                id="idNumber"
                name="idNumber"
                type="text"
                value={formData.idNumber}
                onChange={handleChange}
                className="form-control"
                placeholder="Enter Agent Number"
              />
              <p className="text-red-500 text-xs mt-1">{formErrors.idNumber}</p>
            </div> */}
            <div className="form-group">
              <label className="form-label">
                {t("email")}
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "1px",
                    marginRight: "4px",
                  }}
                >
                  *
                </span>
              </label>

              <input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                className="form-control"
                placeholder="Enter email"
              />
              <p className="text-red-500 text-xs mt-1">{formErrors.email}</p>
            </div>
            <div className="form-group">
              <label className="form-label">
                {t("password")}
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "1px",
                    marginRight: "4px",
                  }}
                >
                  *
                </span>
              </label>

              <input
                id="password"
                name="password"
                type="text"
                value={formData.password}
                onChange={handleChange}
                className="form-control"
                placeholder="Password"
              />
              <p className="text-red-500 text-xs mt-1">{formErrors.password}</p>
            </div>
            <div className="form-group">
              <label className="form-label">
                {t("country")}
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "1px",
                    marginRight: "4px",
                  }}
                >
                  *
                </span>
              </label>
              <Select
                id="country"
                name="country"
                value={countryOptions.find(
                  (option) => option.value === formData.country
                )}
                onChange={(selectedOption) =>
                  handleChange({
                    target: { name: "country", value: selectedOption.value },
                  })
                }
                options={countryOptions}
                placeholder="Select"
              />
              <p className="text-red-500 text-xs mt-1">{formErrors.country}</p>
            </div>
            <div className="form-footer">
              <button
                className="btn btn-primary btn-block"
                onClick={handleCreateAccount}
              >
                {t("create")}
              </button>
            </div>
          </div>
          <div className="text-center text-muted">
            {t("alreadyAccountExists")} <Link to="/login">Sign In</Link>
          </div>
        </div>
      </div>
      <div className="auth_right">
        <div
          className="carousel slide"
          data-ride="carousel"
          data-interval={3000}
        >
          <div className="carousel-inner">
            <div className="carousel-item active items-center">
              <img
                src="assets/images/slider10.png"
                className="img-fluid"
                alt="login page"
                style={{ margin: "auto" }}
              />
              <div className="px-4 mt-4">
                <h4>{t("itemOnetitle")}</h4>
                <p>{t("itemOnedescription")}</p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="assets/images/slider11.png"
                className="img-fluid"
                alt="login page"
                style={{ margin: "auto" }}
              />
              <div className="px-4 mt-4">
                <h4>{t("itemTwotitle")}</h4>
                <p>{t("itemTwodescription")}</p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="assets/images/slider9.png"
                className="img-fluid"
                alt="login page"
                style={{ margin: "auto" }}
              />
              <div className="px-4 mt-4">
                <h4>{t("itemThreetitle")}</h4>
                <p>{t("itemThreedescription")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
